.inferences {
    display: flex;
    color: #FEFEFE;
    flex-direction: row;
    gap: 1rem;
}

.charts {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%; 
    margin-top: 1rem;
}

.pie_Chart {
    background-color: #1C1C1C;
    color: #FEFEFE;
    padding: 1rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
}

.pie_Chart > p, .bar_Chart > p {
    background-color: #242424;
    padding: 0.5rem;
    border-radius: 1rem;
    width: 70%;
}

.bar_Chart {
    background-color: #1C1C1C;
    color: #FEFEFE;
    padding: 1rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
}

@media (max-width: 768px) {
    .charts {
        display: flex;
        flex-direction: column;
    }

    .pie_Chart {
        display: flex;
        margin-bottom: 10rem;
    }

    .bar_Chart {
        display: flex;
    }
}