.popup_Content .highlighted-label {
    display: block;
    margin-bottom: 1rem;
    font-weight: 500;
  }
  
  .popup_Content .highlighted-label input,
  .popup_Content .highlighted-label select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #2980b9; /* Cor de borda destacada */
    appearance: none;
    border-radius: 0.3rem;
    font-size: 0.9rem;
  }
  
  .popup_Content .highlighted-label input:focus,
  .popup_Content .highlighted-label select:focus {
    outline: none; /* Remova a borda de foco padrão */
    border-color: #2980b9; /* Cor de destaque quando o campo está em foco */
  }
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente para desfoque */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup_Content {
    background-color: #FEFEFE;
    border-radius: 1rem;
    padding: 2rem;
    width: 90%; /* Reduzindo um pouco para melhor ajuste */
    max-width: 80%; /* Ajustado para usar a largura máxima de 80% da tela */
    max-height: 90%; /* Altura máxima de 90% da tela */
    overflow-y: auto; /* Adicionando scroll vertical se o conteúdo for maior que a tela */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .popup_Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px); /* Efeito de desfoque no fundo */
  }
  
  .popup_Content h1 {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  
  .popup_Content p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .popup_Content form {
    margin-top: 1.5rem;
  }
  
  .popup_Content label {
    display: block;
    margin-bottom: 1rem;
  }
  
  .popup_Content select,
  .popup_Content input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    font-size: 0.9rem;
  }
  
  .popup_Content .highlighted-field {
    border-color: #2980b9; /* Cor de destaque para os campos importantes */
  }
  
  .popup_Content button {
    margin-top: 1rem;
    padding: 0.8rem;
    width: 100%;
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .done_Button {
    background-color: var(--primary-blue);
    color: white;
  }
  
  .cancel_Button {
    background-color: #e74c3c;
    color: white;
  }

.popup_Content > form  > label > select {
  appearance: none;
}

  @media (max-width: 768px) {
    .popup_Content {
      max-width: 95%; /* Reduz ainda mais o máximo de largura para telas menores */
    }
  }
  