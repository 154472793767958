.register_Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    color: var(--font-color);
}

.register_Form {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background-color: var(--secondary-color);
}

.register_Form > form {
    display: flex;
    width: 50%;
    gap: 0.5rem;
    flex-direction: column;
}

.register_Form > form > h2 {
    font-size: 2rem;
    font-weight: 600;
}

.register_Form > form > label {
    font-size: 0.8rem;
    opacity: 0.5;
}

.register_Form > form > input {
    padding: 1rem;
    background-color: var(--primay-color);
    border: 1px solid var(--primary-gray);
    border-radius: 0.5rem;
}

.register_Form > form > button {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 1rem;
    color: var(--secondary-color);
    transition: background-color 0.3s, color 0.3s; /* Add smooth transitions */
}

.register_Form > form > button:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.register_Footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    color: var(--font-color);
    width: 50%;
    font-size: 0.8rem;
}

.register_Footer_Content {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    filter: opacity(0.5);
    justify-content: space-between;
}

.register_Banner {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-color);
    height: 100vh;
    background-size: cover; 
    background-position: center; 
    background-image: url('/public/images/login\ and\ register/background.png');
}

.register_Banner > h1 {
    font-size: 4rem;
    font-weight: 500;
}

.register_Link {
    color: var(--primary-color);
    font-weight: 600
}

.register_Link:hover {
    text-decoration: underline;
    cursor: pointer;;
}

.register_Contact {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.error-message {
    color: red;
    background-color: #ffe6e6;
    border: 1px solid red;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 0.8rem;
    white-space: pre-line;
}

@media only screen and (max-width: 768px) {
    .register_Banner {
        display: none;
    }

    .register_Form {
        width: 100%;
    }

    .register_Form > form {
        width: 70%;
    }

    .register_Footer {
        width: 100%;
    }
}