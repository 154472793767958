.cameras {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    padding-left: 6.5rem;
    box-sizing: border-box;
}

.cameras_Header {
    margin-bottom: 1rem;
    background-color: var(--primary-blue);
    padding: 1rem;
    display: flex;
    position: fixed;
    border-radius: 2rem;
    bottom: 1rem;
    align-items: center;
    gap: 1rem;
    color: #FEFEFE;
}

.cameras_Header > button {
    background-color: var(--primary-blue);
    border: none;
    cursor: pointer;
    color: #FEFEFE;
}

.cameras_Header > select {
    appearance: none;
    border: none;
    background-color: #FEFEFE;
}

.cameras_Content {
    display: grid;
    flex: 1;
    overflow-y: auto;
    padding-bottom: 5rem; 
}

.single-camera .cameras_Content {
    grid-template-columns: repeat(1, 1fr);
}

.four-camera .cameras_Content {
    grid-template-columns: repeat(2, 1fr);
}

.nine-camera .cameras_Content {
    grid-template-columns: repeat(3, 1fr);
}

.twelve-camera .cameras_Content {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

.sixteen-camera .cameras_Content {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
}

.camera {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 0.5rem;
    background-color: white;
}

.camera > video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajusta o vídeo para cobrir o tamanho do contêiner */
}

.empty {
    background-color: rgba(254, 254, 254, 0); /* Cor de fundo para células vazias */
}

@media only screen and (max-width: 1200px) {
    .cameras_Content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 768px) {
    .cameras_Content {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .cameras {
        padding-left: 0rem;
        padding: 1rem;
        padding-bottom: 1rem;
    }

    .cameras_Header {
        display: flex;
        position: fixed;
        bottom: 6rem;
    }

    .camera > video {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}
