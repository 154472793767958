.dashboard_Launch {
    padding-left: 6rem;
    padding-right: 1rem;
    background-color: #131313;
}

.data {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.data_Profile {
    background: linear-gradient(145deg, #1C1C1C, #07093f);
    color: #364AB2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    width: 50%;
    height: 100%;
    border-radius: 1rem;
    padding: 2rem;
}

.data_Name {
    text-align: center;
    color: #FFFFFF;
}

.data_Info {
    text-align: center;
    color: #FFFFFF;
    font-weight: 200;
}

.data_Button {
    color: #364AB2;
    border-radius: 0.5rem;
    border: 1px solid #364AB2;
    padding: 0.5rem;
    text-decoration: none;
}

.data_Plan {
    background: linear-gradient(145deg, #1C1C1C, #07093f);
    color: #FFFFFF;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    border-radius: 1rem;
    padding: 2rem;
}

.data_Plan > h3 {
    font-weight: 400;
}

.data_Plan > p {
    font-size: 0.8rem;
    font-weight: 200;
}

.data_Plan > button {
    padding: 0.5 0 0 0.5rem;
    text-align: center;
    background-color: #2AEAB8;
    border: none;
    width: 30%;
    border-radius: 1rem;
}

@media only screen and (max-width: 768px) {
    .dashboard_Launch {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 100%;
    }

    .data_Plan {
        width: 90%;
    }

    .data_Profile {
        width: 90%;
    }
}