.modal_Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_Content {
  background: #FEFEFE;
  padding: 2rem;
  border-radius: 2rem;
  width: 300px;
  max-width: 90%;
}

.modal_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modal_Header > h2 {
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.modal_Body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filter_Label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.filter_Text {
  flex: 1;
  margin-right: 10px;
}

.modal_Footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
}

.modal_Button_Confirm {
  background: var(--primary-blue);
  color: white;
  border: none;
  padding: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 2rem;
}

.modal_Button_Close {
  background: #FEFEFE;
  color: var(--primary-blue);
  font-weight: 400;
  border: none;
  padding: 1rem;
  cursor: pointer;
  border-radius: 1rem;
}

.modal_Button:hover {
  background: #0056b3;
}

.filter_Checkbox {
  background-color: #FEFEFE;
  border: 1px solid var(--primary-blue);
  padding: 0.5rem;
  border-radius: 0.3rem;
  appearance: none;
}

.filter_Checkbox:checked {
  background-color: var(--primary-blue);
}
