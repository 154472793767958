/* Estilos gerais */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0; /* Ajuste conforme necessário */
}

/* Estilo para o dashboard */
.dashboard {
    display: flex;
    min-height: 100vh;
    background-color: #f0f0f0; /* Ajuste conforme necessário */
}

/* Estilo para dashboard_Left (comentado no JSX, mas mantenha para referência futura) */
.dashboard_Left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 20%; /* Ajuste conforme necessário */
    background-color: #6F4E37; /* Ajuste conforme necessário */
}

/* Estilo para dashboard_Right */
.dashboard_Right {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Garante que a altura mínima seja 100% da viewport */
    width: 100%; /* Ajuste conforme necessário */
    background-color: #FED8B1; /* Ajuste conforme necessário */
}

/* Estilos responsivos */
@media only screen and (max-width: 768px) {
    .dashboard_Right {
        height: 89.5vh;
    }
}
