.viewer_Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .viewer {
    padding: 1rem;
    padding-left: 6rem;
  }

  .camera video {
    object-fit: cover; 
    object-position: center; 
  }
  
  .single-camera .grid-item {
    overflow: hidden;
    object-fit: cover; 
    object-position: center;
    width: 90vw;
    background-size: cover;
    background-position: center;
  }
  
  .four-camera .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.2rem;
    width: 100vw;
    height: 90vh;
  }
  
  .nine-camera .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.2rem;
    width: 100vw;
    height: 90vh;
  }

  .twelve-camera .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.2rem;
    width: 100vw;
    height: 90vh;
  }
  .sixteen-camera .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.2rem;
    width: 100vw;
    height: 90vh;
  }
  
  .grid-item {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .grid-item img {
    width: 100%;
    height: 100%;
  }
  
  .grid-controls {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--secondary-blue);
    padding: 0.5rem;
    border-radius: 3rem;
  }
  
  
  .grid-controls button {
    display: flex;
    background-color: var(--secondary-blue);
    border: none;
    color: #FEFEFE;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 4rem;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
  }
  
  .grid-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .grid-controls button:hover:not(:disabled) {
    background-color: var(--primary-blue);
  }
  
  .navigation-controls {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 20px;
  }
  
  .navigation-controls button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: var(--secondary-blue);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .navigation-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .navigation-controls button:hover:not(:disabled) {
    background-color: var(--primary-blue);
  }
  
  @media only screen and (max-width: 768px) {
    .viewer {
      padding-left: 1rem;
    }

    .navigation-controls {
      width: 95%;
      margin-bottom: 4.5rem;
    }

    .grid-controls {
      margin-bottom: 4rem;
    }
  }


  