.iframe {
    padding-left: 6rem;
    width: 100%; 
    height: 100vh;
    box-sizing: border-box; 
  }
  

  @media (max-width: 1200px) {
    .iframe {
      padding-left: 6rem;
    }
  }
  
  @media (max-width: 768px) {
    .iframe {
      padding-left: 0rem;
    }
  }
  
  @media (max-width: 480px) {
    .iframe {
      padding-left: 0rem;
      height: 80vh; 
    }
  }
  